
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import RankingInGame from '../views/RankingInGame.vue';

@Component({
  components: {
    RankingInGame,
  },
})
export default class WrapperRanking extends Vue {
  @Prop() readonly token: string;

  GeneralControlModule = getModule(GeneralControl, this.$store);

  type = 'level';

  hoverLevel = false;

  hoverTurn = false;

  hoverMulti = false;

  hoverWinlose = false;

  hoverDaily = false;

  onLevel = false;

  onTurn = false;

  onMulti = false;

  onWinlose = false;

  onDaily = false;

  created() {
    this.onLevel = true;
  }

  handleHover(type: string, flag: boolean) {
    if (type === 'level') this.hoverLevel = flag;
    if (type === 'turnover') this.hoverTurn = flag;
    if (type === 'multiplier') this.hoverMulti = flag;
    if (type === 'winlose') this.hoverWinlose = flag;
    if (type === 'daily') this.hoverDaily = flag;
  }

  handleMenu(type: string, flag: boolean) {
    if (type === 'level' && flag) {
      this.onLevel = flag;
    } else {
      this.onLevel = false;
    }
    if (type === 'turnover' && flag) {
      this.onTurn = flag;
    } else {
      this.onTurn = false;
    }
    if (type === 'multiplier' && flag) {
      this.onMulti = flag;
    } else {
      this.onMulti = false;
    }
    if (type === 'winlose' && flag) {
      this.onWinlose = flag;
    } else {
      this.onWinlose = false;
    }
    if (type === 'daily' && flag) {
      this.onDaily = flag;
    } else {
      this.onDaily = false;
    }
    this.type = type;
  }

  closeRanking() {
    this.GeneralControlModule.updateRankingModalState(false);
    this.handleMenu('level', true);
  }
}
