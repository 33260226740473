
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import HistoryInstance from '@/store/modules/History';

@Component
export default class History extends Vue {
  @Prop() readonly changePeriod!: Function;

  HistoryModule = getModule(HistoryInstance, this.$store);

  periodList = [
    'TODAY',
    'YESTERDAY',
    'WEEKLY',
  ];
}
