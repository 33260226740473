var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"history-box-ranking d-flex"},[_c('vue-scroll',[_c('div',{staticClass:"inner font-weight-bold"},[_c('div',{staticClass:"header d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"col -no size-text user"},[_vm._v("User")]),_c('div',{staticClass:"col -value text-right size-text mode"},[_vm._v(" "+_vm._s(_vm.type.charAt(0).toUpperCase() + _vm.type.slice(1))+" ")])]),_c('div',{staticClass:"d-flex row-record -you"},[_c('div',{staticClass:"inner d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"col -no text-left size-text"},[_vm._v("You")]),_c('div',{staticClass:"col -name text-left d-flex align-items-center"},[_c('div',[_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"avatar-rank"},[_c('img',{attrs:{"src":require(
                      // eslint-disable-next-line max-len
                      `@/assets/images/${_vm.lists.self.name}-${_vm.lists.self.rankCount}.png`
                    ),"alt":""}}),_c('div',{class:`avatar-star -star-${_vm.lists.self.star} d-flex`},_vm._l((_vm.lists.self.star),function(i){return _c('div',{key:i,staticClass:"star-box"},[(_vm.lists.self)?_c('img',{attrs:{"src":require(
                          `@/assets/images/Star-${_vm.lists.self.name}.png`
                        ),"alt":""}}):_vm._e()])}),0)]),_c('div',{staticClass:"avatar-image"},[_c('img',{attrs:{"src":`${_vm.lists.self.imageAvatar}`,"alt":""}})])])]),_c('div',{staticClass:"size-text ml-2"},[_vm._v(" "+_vm._s(_vm.lists.self && _vm.lists.self.username)+" ")])]),(_vm.type === 'level')?_c('div',{staticClass:"-value text-right size-text valcolor"},[_vm._v(" "+_vm._s(Math.round(_vm.lists.self[_vm.type]))+" ")]):_c('div',{staticClass:"-value text-right size-text size valcolor",attrs:{"warp":""}},[_vm._v(" "+_vm._s(_vm.lists.self.ranking[_vm.type])+" ")])])]),_vm._l((_vm.lists.lists),function(item,index){return _c('div',{key:index,staticClass:"d-flex row-record",class:{'-top': index <= 2}},[_c('div',{staticClass:"inner d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"col -no text-left"},[_vm._v(_vm._s(index += 1))]),_c('div',{staticClass:"col -name text-left d-flex align-items-center"},[_c('div',[_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"avatar-rank"},[_c('img',{attrs:{"src":require(
                      `@/assets/images/${item.name}-${item.rankCount}.png`
                    ),"alt":""}}),_c('div',{class:`avatar-star -star-${item.star} d-flex`},_vm._l((item.star),function(i){return _c('div',{key:i,staticClass:"star-box"},[(item)?_c('img',{attrs:{"src":require(
                          `@/assets/images/Star-${item.name}.png`
                        ),"alt":""}}):_vm._e()])}),0)]),_c('div',{staticClass:"avatar-image"},[_c('img',{attrs:{"src":`${item.imageAvatar}`,"alt":""}})])])]),_c('div',{staticClass:"size-text ml-2"},[_vm._v(_vm._s(item && item.username))])]),(_vm.type === 'level')?_c('div',{staticClass:"-value text-right size-text valcolor"},[_vm._v(" "+_vm._s(Math.round(item[[_vm.type]]))+" ")]):_c('div',{staticClass:"-value text-right size-text valcolor"},[_vm._v(" "+_vm._s(item.ranking[[_vm.type]])+" ")])])])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }