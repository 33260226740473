
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import HistoryInstance from '@/store/modules/History';
import momentTz from 'moment-timezone';

@Component
export default class PointsHistory extends Vue {
  HistoryModule = getModule(HistoryInstance, this.$store);

  color(val: number) {
    let color = '';
    if (val > 0) {
      color = 'greentext';
    }
    if (val < 0) {
      color = 'redtext';
    }
    return color;
  }

  colorBalance(val: number) {
    return val ? 'yellowtext' : '';
  }

  getDate(getDate: any) {
    return momentTz(getDate).format('YYYY-MM-DD hh:mm:ss a');
  }
}
