
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Deposit from '@/store/modules/Deposit';
import GeneralControl from '@/store/modules/GeneralControl';

interface IGetQRcode {
  username: string;
  agentId: string;
  amount: number;
}

@Component
export default class QR extends Vue {
  DepositModule = getModule(Deposit, this.$store);

  GeneralControlModule = getModule(GeneralControl, this.$store);

  amount = '';

  qrBase64 = '';

  loading = false;

  async QRrefill() {
    const params: IGetQRcode = {
      username: this.DepositModule.refillProfile.username,
      agentId: this.DepositModule.refillProfile.source,
      amount: Number(this.amount),
    };
    this.qrBase64 = '';
    this.loading = true;
    const res = await this.DepositModule.fetchQr(params);
    this.loading = false;
    if (res.status.code === 0) {
      this.amount = '';
      this.qrBase64 = res.data.result.value;
    }
  }
}
