
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Deposit from '@/store/modules/Deposit';
import CashBackStore from '@/store/modules/Cashback';

@Component
export default class Cashback extends Vue {
  DepositModule = getModule(Deposit, this.$store);

  CashBackModule = getModule(CashBackStore, this.$store);

  @Prop() readonly reciveBonus!: boolean;

  @Prop() readonly token!: string;

  isBonus = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  code: any;

  error = '';

  success = '';

  disableStatus = false;

  cashbackBalance = 0;

  statusSuccess = false;

  created() {
    this.isBonus = this.reciveBonus;
    this.fetchCashback();
  }

  async fetchCashback() {
    const res: any = await this.CashBackModule.fecthCashback(this.token);
    if (res.status.code === 0) {
      this.cashbackBalance = res.data.result.value;
      this.disableStatus = Number(res.data.result.value) > 0;
    }
  }

  async cashbackSubmit(): Promise<void> {
    const res: any = await this.CashBackModule.getCashback(this.token);
    this.code = res.code;
    if (res.status.code === 0) {
      this.statusSuccess = true;
      setTimeout(() => {
        this.$router.go(0);
      }, 1500);
    } else {
      this.error = res.status.message;
    }
  }
}
