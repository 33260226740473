
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import Deposit from '@/store/modules/Deposit';
import Auto from '@/components/deposit/Auto.vue';
import Float from '@/components/deposit/Float.vue';
import True from '@/components/deposit/True.vue';
import QR from '@/components/deposit/QR.vue';
import Withdraw from '@/components/deposit/Withdraw.vue';
import Cashback from '@/components/deposit/Cashback.vue';
import HistoryInstance from '@/store/modules/History';

@Component({
  components: {
    Auto,
    Float,
    True,
    QR,
    Withdraw,
    Cashback,
  },
})
export default class DepositModal extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  DepositModule = getModule(Deposit, this.$store);

  HistoryModule = getModule(HistoryInstance, this.$store);

  @Prop() readonly reciveBonus!: boolean;

  @Prop() readonly mode!: string;

  @Prop() readonly token!: string;

  @Prop() readonly closeDeposit!: boolean;

  @Prop() readonly detail!: object;

  @Prop() readonly isExitBtn!: boolean;

  @Prop() readonly backLink!: string;

  currentModal = 'auto';

  userVerify = [];

  modalList = [
    {
      mode: 'auto',
      text: 'deposit.auto',
      filter: 'deposit',
    },
    {
      mode: 'float',
      text: 'deposit.float',
      filter: 'bankFloat',
    },
    {
      mode: 'true',
      text: 'TrueWallet',
      filter: 'truewallet',
    },
    {
      mode: 'qr',
      text: 'QRCode',
      filter: 'vPay',
    },
    {
      mode: 'withdraw',
      text: 'withdraw.header',
      filter: 'withdraw',
    },
    {
      mode: 'cashback',
      text: 'cashback.header',
      filter: 'cashback',
    },
  ];

  finalModal = [];

  textModal: any = {};

  async mounted() {
    this.textModal = this.modalList.find((item) => item.mode === this.currentModal);
    const depositActiveList = await this.DepositModule.verrifyPayment();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredActive = depositActiveList
      .filter((item: any) => item.isActive)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((item: any) => item.name);
    this.modalList = this.modalList.filter((item) => filteredActive.includes(item.filter));
    this.currentModal = this.modalList[0].mode;
  }

  closeModal() {
    this.GeneralControlModule.updateDepositModalState(false);
  }

  async openHistory() {
    this.GeneralControlModule.updateModalState(true);
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    await this.HistoryModule.fetchWinLose();
    this.HistoryModule.fetchHistory({ time: 'TODAY', date: this.$moment() });
  }

  async exitGame() {
    const version = await this.GeneralControlModule.getVersion();
    const url = version[this.mode];
    if (!this.backLink) {
      window.location.href = `${url}?t=${this.token}`;
    } else {
      window.location.href = this.backLink;
    }
  }
}
