
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import ExchangePoint from '@/store/modules/ExchangePoint';
import { io } from 'socket.io-client';
import { SOCKET_LOTTO } from '@/utils/api';
import LottoControl from '@/store/modules/Lotto';

export enum Page {
  info = 'info',
  buy = 'buy',
  history = 'history',
  main = 'main',
}

@Component({
})
export default class Lotto extends Vue {
  @Prop() readonly userToken: string;

  @Prop() readonly lang: string;

  @Prop() readonly modalState: string;

  @Prop() readonly fontStyle!: Function;

  GeneralControlModule = getModule(GeneralControl, this.$store);

  ExchangePointModule = getModule(ExchangePoint, this.$store)

  LottoModule = getModule(LottoControl, this.$store)

  isConnect = false;

  processData = {
    isProcess: false,
    isSucess: false,
  }

  msgError = '';

  socket: any = {};

  userLottoProfile = {};

  inputFristNum = '0';

  inputSecondNum = '0';

  stateConnect = this.GeneralControlModule.isModalLottoState;

  isPage = 'main';

  fristNumber = 0;

  secondNumber = 0;

  ticketTotal = 0;

  totalPrice = 1000;

  isBetState = true;

  isSucessProcess = false;

  userHistory = [];

  tranform = 0.2;

  lottoStatistics = [];

  msgAlertBuy = '';

  componentKeyFirst = 0;

  componentKeySecond = 0;

  @Watch('GeneralControlModule.isModalLottoState', { immediate: true, deep: true })
  connectSocket(val: any) {
    if (val) {
      this.connectSocketLotto();
    }
  }

  @Watch('LottoModule.resultNumber', { immediate: true, deep: true })
  covertResult(val: any) {
    if (val) {
      const number = val.split('');
      this.fristNumber = parseInt(number[0], 10);
      this.secondNumber = parseInt(number[1], 10);
      this.componentKeyFirst += 1;
      this.componentKeySecond += 1;
    }
  }

  @Watch('ticketTotal', { immediate: true, deep: true })
  changeTicket(val: any) {
    if (val) {
      this.calTicket();
    }
  }

  @Watch('inputFristNum', { immediate: true, deep: true })
  changeInputSecond(val: any) {
    if (val) {
      document.getElementById('secondNumber')!.focus();
    }
  }

  @Watch('inputSecondNum', { immediate: true, deep: true })
  changeInputFirst(val: any) {
    if (val === '') {
      document.getElementById('secondNumber')!.addEventListener('keydown', this.changeInput);
    } else if (val && this.inputFristNum === '') {
      document.getElementById('firstNumber')!.focus();
    }
  }

  changeInput(event: any) {
    if (this.inputSecondNum === '' && event.key === 'Backspace') {
      document.getElementById('firstNumber')!.focus();
    }
  }

  onBuyLotto() {
    if (this.LottoModule.isPlaying
      && this.inputFristNum !== ''
      && this.inputSecondNum !== ''
      && this.ticketTotal !== 0) {
      this.socket.emit('bet', {
        numbers: {
          a: parseInt(this.inputFristNum, 10),
          b: parseInt(this.inputSecondNum, 10),
        },
        ticketAmount: Number(this.ticketTotal),
      }, (res: any) => {
        this.isBetState = false;
        if (res.status.code) {
          this.msgAlertBuy = `lotto.alert_${res.status.code}`;
          this.isSucessProcess = false;
        } else {
          this.isSucessProcess = true;
          this.LottoModule.setAmountPoints(res.data.points);
          this.LottoModule.setAllTicket(res.data.totalTicket);
        }
      });
    }
  }

  fecthStatistics() {
    this.socket.emit('statistics', { }, (res: any) => {
      if (res.data) {
        this.lottoStatistics = res.data.statistics;
      }
    });
  }

  async connectSocketLotto() {
    this.socket = await io(SOCKET_LOTTO, {
      auth: { token: `Bearer ${this.userToken}` },
      transports: ['websocket'],
      withCredentials: false,
      secure: false,
    });
    this.socket.on('connect', () => {
      this.socket.on('Authenticated', async (res: any) => {
        this.lottoSocketOn();
        this.userLottoProfile = res.data;
        this.LottoModule.setAmountPoints(res.data.points);
        this.LottoModule.setAllTicket(res.data.totalTicket);
        this.LottoModule.setRound(res.data.round);
        const number = res.data.lastNumbers.split('');
        this.fristNumber = parseInt(number[0], 10);
        this.secondNumber = parseInt(number[1], 10);
      });
    });
  }

  lottoSocketOn() {
    this.socket.on('before_start', async (res: any) => {
      this.LottoModule.setIsProgressBar(true);
      this.LottoModule.setIsPlaying(false);
    });
    this.socket.on('start', async (res: any) => {
      this.LottoModule.setIsPlaying(true);
      this.LottoModule.setProgressBar(100);
    });
    this.socket.on('result', async (res: any) => {
      this.LottoModule.setIsPlaying(false);
      this.LottoModule.setResultNumber(res.data.result);
    });
    this.socket.on('disconnect', async (res: any) => {
      this.isConnect = true;
    });
  }

  goBuy() {
    this.initial();
    this.isPage = 'buy';
  }

  async goHistory() {
    this.isPage = 'history';
    this.initial();
    const res = await this.LottoModule.fecthLottoHistory();
    this.userHistory = res.data;
  }

  goMain() {
    this.isPage = 'main';
    this.initial();
  }

  goInfo() {
    this.isPage = 'info';
    this.initial();
  }

  goStatistics() {
    this.isPage = 'statistics';
    this.initial();
    this.fecthStatistics();
  }

  addTicket() {
    const availableTicket = 50 - this.LottoModule.allTicket;
    if (Number(this.ticketTotal) < availableTicket) {
      this.ticketTotal = Number(this.ticketTotal) + 1;
    } else if (this.ticketTotal >= availableTicket) {
      this.ticketTotal = 1;
    } else if (!availableTicket) {
      this.ticketTotal = 0;
    }
    this.calTicket();
  }

  removeTicket() {
    const availableTicket = 50 - this.LottoModule.allTicket;
    if (this.ticketTotal <= availableTicket && this.ticketTotal > 1) {
      this.ticketTotal -= 1;
    } else if (this.ticketTotal === 1) {
      this.ticketTotal = availableTicket;
    } else if (!availableTicket) {
      this.ticketTotal = 0;
    }
    this.calTicket();
  }

  closeLotto() {
    this.GeneralControlModule.updateLottoModalState(false);
    this.isPage = 'main';
    this.initial();
  }

  calTicket() {
    this.totalPrice = this.ticketTotal * 1000;
  }

  formatter(value: number) {
    const availableTicket = 50 - this.LottoModule.allTicket;
    if (value > availableTicket) {
      return availableTicket;
    } if (value < 1 || !availableTicket) {
      return '';
    }
    return value;
  }

  curryChange(input: number) {
    let value = '';
    if (input) {
      value = input.toLocaleString();
    } else {
      value = '0';
    }
    return value;
  }

  initial() {
    this.inputFristNum = '';
    this.inputSecondNum = '';
    this.ticketTotal = 1;
    this.totalPrice = 1000;
    this.isBetState = true;
    this.isSucessProcess = false;
    this.userHistory = [];
    this.lottoStatistics = [];
    this.msgAlertBuy = '';
    this.isConnect = false;
    this.componentKeyFirst = 0;
    this.componentKeySecond = 0;
  }

  color(luckyPlayer: string) {
    let color = '';
    if (luckyPlayer === '') {
      color = 'yellowtext';
    }
    return color;
  }

  colorValue(val: number) {
    let color = '';
    if (val === null) {
      color = 'yellowtext';
    } else {
      if (val > 0) {
        color = 'greentext';
      } if (val === 0) {
        color = 'redtext';
      }
    }
    return color;
  }

  splitNumberfirst(value: number) {
    const number = value.toString().split('');
    return number[0];
  }

  splitNumberSecond(value: number) {
    const number = value.toString().split('');
    return number[1];
  }

  randomNumber() {
    this.inputFristNum = Math.floor(Math.random() * 10).toString();
    this.inputSecondNum = Math.floor(Math.random() * 10).toString();
  }

  validateNumber(event: any) {
    if (event.target.value > 9) {
      event.preventDefault();
    }
    if ([8, 46, 109, 110].includes(event.keyCode)) event.preventDefault();
    if (event.keyCode < 48 || event.keyCode > 57) {
      event.preventDefault();
    }
  }

  validateTicketNumber(event: any) {
    if (event.target.value.length) {
      const availableTicket = 50 - this.LottoModule.allTicket;
      if (event.target.value.length === 2) {
        event.preventDefault();
      } else if (event.target.value > 50) {
        event.preventDefault();
      } else if (event.target.value > availableTicket) {
        event.preventDefault();
      }
      this.ticketTotal = Number(event.target.value);
      this.calTicket();
    }
    if (event.keyCode < 48 || event.keyCode > 57) event.preventDefault();
    this.ticketTotal = Number(event.target.value);
    this.calTicket();
  }
}
