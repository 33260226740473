
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class EvennPopup extends Vue {
  @Prop() readonly isShowEventDetail!: boolean;

  @Prop() readonly festivalName!: string;

  @Prop() readonly borderEvent!: string;

  @Prop() readonly evetDetail!: string;

  @Prop() readonly fontStyle!: Function;

  @Prop() readonly closeEvent!: Function;
}
