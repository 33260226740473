
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import Deposit from '@/store/modules/Deposit';
import ItemControl from '@/store/modules/Item';

@Component({
})
export default class FeatureMenu extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  DepositModule = getModule(Deposit, this.$store);

  @Prop() readonly statusDot!: any;

  @Prop() readonly token!: string;

  @Prop() readonly promotionPrice!: number;

  @Prop() readonly isRankActive!: boolean;

  @Prop() readonly handleIsOpenBar: Function;

  notiInventory = false;

  ItemControlModule = getModule(ItemControl, this.$store);

  @Watch('statusDot', { immediate: true, deep: true })
  handleDot() {
    this.notiInventory = this.statusDot;
  }

  closeModal() {
    this.GeneralControlModule.updateFeatureMenu(false);
    this.handleIsOpenBar({ isOpen: false });
  }

  openRanking() {
    this.GeneralControlModule.updateRankingModalState(true);
    this.GeneralControlModule.updateInventoryModalState(false);
    this.GeneralControlModule.updateStoreModalState(false);
  }

  async openInventory() {
    this.GeneralControlModule.updateInventoryModalState(true);
    this.GeneralControlModule.updateRankingModalState(false);
    this.GeneralControlModule.updateStoreModalState(false);
  }

  openStoreExchange() {
    this.GeneralControlModule.updateStoreModalState(true);
    this.GeneralControlModule.updateInventoryModalState(false);
    this.GeneralControlModule.updateRankingModalState(false);
  }
}
