
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import HistoryInstance from '@/store/modules/History';
import Deposit from '@/store/modules/Deposit';
import ItemControl from '@/store/modules/Item';
import LottoControl from '@/store/modules/Lotto';

@Component({
})
export default class Bar extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  HistoryModule = getModule(HistoryInstance, this.$store);

  DepositModule = getModule(Deposit, this.$store);

  ItemControlModule = getModule(ItemControl, this.$store);

  LottoModule = getModule(LottoControl, this.$store)

  @Prop() readonly expPercentage!: number;

  @Prop() readonly token!: string;

  @Prop() readonly detail!: object;

  @Prop() readonly mode!: string;

  @Prop() readonly avatar!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly event!: any;

  @Prop() readonly lang!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly isActiveDropRate!: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly isActiveExpRate!: any;

  @Prop() readonly isLevelActive!: boolean;

  @Prop() readonly isDropActive!: boolean;

  @Prop() readonly isRankActive!: boolean;

  @Prop() readonly isMentananceLevel!: boolean;

  @Prop() readonly statusDot!: any;

  @Prop() readonly gotDuplicate!: Function;

  @Prop() readonly isLotto!: boolean;

  @Prop() readonly toggleBar!: boolean;

  @Prop() readonly isMobileDevice!: boolean;

  @Prop() readonly handleIsOpenBar: Function;

  baseState = true;

  close = false;

  filterDeposit = [];

  closeDeposit = true;

  closeHistory = true;

  expUpEvent = false;

  itemUpEvent = false;

  boostExp = true;

  boostDrop = false;

  globalDropImg = '';

  globalExpImg = '';

  eventImgOne = '';

  eventImgTwo = '';

  eventImgThree = '';

  eventImgFour = '';

  globalDropText = '';

  globalExpText = '';

  timeDropItem = 0;

  timeExp = 0;

  activeLevel = false;

  activeDrop = false;

  notiInventory = false;

  componentKeyLotto = 0;

  @Watch('statusDot', { immediate: true, deep: true })
  handleDot() {
    this.notiInventory = this.statusDot;
  }

  @Watch('isLotto', { immediate: true, deep: true })
  lottoChange(val: boolean) {
    if (val) {
      this.componentKeyLotto += 1;
    }
  }

  @Watch('toggleBar', { immediate: true, deep: true })
  handleBar() {
    this.toggle();
  }

  async created() {
    this.GeneralControlModule.updateIsHideBar(false);
    const barStatus = localStorage.getItem('barStack');
    if (Number(barStatus) < 1) {
      localStorage.setItem('barStack', '1');
      setTimeout(() => {
        this.baseState = false;
      }, 5000);
    }
    if (Number(barStatus) >= 1) {
      if (Number(barStatus) === 50) {
        localStorage.setItem('barStack', '1');
      } else {
        localStorage.setItem('barStack', String(Number(barStatus) + 1));
      }
    }
    if (Number(barStatus) < 5) {
      this.baseState = true;
      setTimeout(() => {
        this.baseState = false;
      }, 5000);
    } else {
      this.baseState = false;
    }
  }

  async toggle() {
    this.baseState = !this.baseState;
    const dataTicker = {
      isOpen: this.baseState,
    };
    this.GeneralControlModule.tickerUserLevel(dataTicker);
  }

  updated() {
    this.activeLevel = this.isLevelActive;
    this.activeDrop = this.isDropActive;
    // eslint-disable-next-line max-len
    const rateDrop = this.event.rateDropItem ? Number(String(this.event.rateDropItem.dropItemAmountInGame)) : 1;
    const rateExp = this.event.rateExp ? Number(String(this.event.rateExp.expAmountInGame)) : 1;
    if (this.activeDrop) {
      if (rateExp > 1) {
        this.itemUpEvent = true;
        this.globalDropImg = this.event.rateDropItem.image;
        this.globalDropText = this.event.rateDropItem.dropText[this.lang];
      }
    }
    if (this.activeLevel) {
      if (rateDrop > 1) {
        this.expUpEvent = true;
        this.globalExpImg = this.event.rateExp.image;
        this.globalExpText = this.event.rateExp.expText[this.lang];
      }
    }
    // if (this.isActiveDropRate.isActive) {
    //   this.timeDropItem = ((this.isActiveDropRate.HH * 60) * 60)
    //   + (this.isActiveDropRate.mm * 60);
    // }
    // if (this.isActiveExpRate.isActive) {
    //   this.timeExp = ((this.isActiveExpRate.HH * 60) * 60)
    //     + (this.isActiveExpRate.mm * 60);
    // }
  }

  async openHistory() {
    this.GeneralControlModule.updateModalState(true);
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    await this.HistoryModule.fetchWinLose();
    this.HistoryModule.fetchHistory({ time: 'TODAY', date: this.$moment() });
  }

  async openDeposit() {
    this.GeneralControlModule.updateDepositModalState(true);
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    await this.DepositModule.fetchAutoProfile(this.token);
    await this.DepositModule.fetchAutoInfo();
  }

  async openFeatureBtn() {
    this.handleIsOpenBar({
      isOpen: true,
    });
    this.GeneralControlModule.updateFeatureMenu(true);
  }

  openRanking() {
    this.GeneralControlModule.updateRankingModalState(true);
  }

  openInventory() {
    this.GeneralControlModule.updateInventoryModalState(true);
  }

  openStoreExchange() {
    this.GeneralControlModule.updateStoreModalState(true);
  }

  openLotto() {
    this.GeneralControlModule.updateLottoModalState(true);
  }

  handleExit() {
    this.close = true;
  }

  handleClose() {
    this.handleIsOpenBar({
      isOpen: true,
    });
    this.close = false;
  }

  // eslint-disable-next-line consistent-return
  handleSocket(val: string) {
    if (!val) return 'close-socket';
  }

  async exitGame() {
    const version = await this.GeneralControlModule.getVersion();
    const url = version[this.mode];
    window.location.href = `${url}?t=${this.token}`;
  }
}
