import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Result from '@/views/Result.vue';
import Home from '../views/Home.vue';
import HistoryPage from '../views/HistoryPage.vue';
import WinLosePage from '../views/WinLosePage.vue';
import RankingPage from '../views/RankingPage.vue';
import ToggleHistory from '../views/ModalFramePage.vue';
import Demo from '../views/Demo.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/play_v*',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/history',
    name: 'HistoryPage',
    component: HistoryPage,
  },
  // {
  //   path: '/play_v*/history',
  //   name: 'HistoryPage',
  //   component: HistoryPage,
  // },
  {
    path: '/winlose',
    name: 'WinLosePage',
    component: WinLosePage,
  },
  // {
  //   path: '/play_v*/winlose',
  //   name: 'WinLosePage',
  //   component: WinLosePage,
  // },
  {
    path: '/ranking',
    name: 'RankingPage',
    component: RankingPage,
  },
  // {
  //   path: '/play_v*/ranking',
  //   name: 'RankingPage',
  //   component: RankingPage,
  // },
  {
    path: '/togglehistory',
    name: 'ToggleHistory',
    component: ToggleHistory,
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
  },
  // {
  //   path: '/play_v*/togglehistory',
  //   name: 'ToggleHistory',
  //   component: ToggleHistory,
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
