import { render, staticRenderFns } from "./RankingInGame.vue?vue&type=template&id=96f9ef76&scoped=true"
import script from "./RankingInGame.vue?vue&type=script&lang=ts"
export * from "./RankingInGame.vue?vue&type=script&lang=ts"
import style0 from "./RankingInGame.vue?vue&type=style&index=0&id=96f9ef76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96f9ef76",
  null
  
)

export default component.exports