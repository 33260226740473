var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropType && _vm.isDropActive),expression:"dropType && isDropActive"}],staticClass:"gift-wrapper",on:{"keypress":function($event){return _vm.upEvent('drop')}}},[_c('div',{staticClass:"wrapper-item-drop scale-wrapper",class:_vm.fontStyle()},[(_vm.dropType !== 'luckyPlayer')?_c('label',{class:`text-head-drop-${_vm.lang}`},[_vm._v(" "+_vm._s(_vm.$t('item.congratulations'))+" ")]):_c('label',{class:`text-head-drop-${_vm.lang}`},[_vm._v(" "+_vm._s(_vm.popupText.header[this.lang])+" ")]),_c('v-lazy-image',{staticClass:"asset-banner-lv",attrs:{"src":_vm.dropBorderImg}}),(_vm.dropType !== 'luckyPlayer')?_c('v-lazy-image',{staticClass:"asset-item-drop",attrs:{"src":_vm.giftUrl}}):_c('v-lazy-image',{staticClass:"asset-item-drop-lotto",attrs:{"src":_vm.giftUrl}}),_c('div',{staticClass:"amount-drop"},[_c('div',{staticClass:"amount-coin"},[(_vm.dropAmount)?_c('div',{staticClass:"row"},[_c('v-lazy-image',{staticClass:"coin-size-drop",attrs:{"src":require(
            `@/assets/images/PGSlot_Lobby_Icon_Coin.png`
          )}}),_c('span',{staticClass:"coin-drop",class:_vm.fontStyle()},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.dropAmount))+" ")])],1):_vm._e(),(_vm.dropPoint)?_c('div',{staticClass:"row"},[_c('v-lazy-image',{staticClass:"coin-size-drop",attrs:{"src":require(
            `@/assets/images/Store/icon_point.png`
          )}}),_c('span',{staticClass:"coin-drop",class:_vm.fontStyle()},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.dropPoint))+" ")])],1):_vm._e()])]),_c('div',{staticClass:"wrapper-detail-drop"},[_c('div',{staticClass:"message-recive-item-detail",class:_vm.fontStyle()},[_vm._v(" "+_vm._s(_vm.dropMsg)+" ")]),(_vm.dropType !== 'luckyPlayer')?_c('div',{staticClass:"message-recive-item",class:_vm.fontStyle(),domProps:{"innerHTML":_vm._s(_vm.dropMsggift)}}):_vm._e(),(_vm.dropType === 'luckyPlayer')?_c('div',{staticClass:"message-recive-item-alert",class:_vm.fontStyle(),domProps:{"innerHTML":_vm._s(_vm.popupText.footer[_vm.lang])}}):_vm._e()]),_c('div',{staticClass:"btn-close-banner-drop",on:{"click":function($event){return _vm.upEvent('drop')}}},[_c('div',{staticClass:"border-close-banner"},[(_vm.dropType === 'luckyPlayer')?_c('v-lazy-image',{staticClass:"btn-banner",attrs:{"src":require(
          `@/assets/images/Lotto/btn_popup.png`
        )}}):_c('v-lazy-image',{staticClass:"btn-banner",attrs:{"src":require(
          `@/assets/images/button_normal.png`
        )}}),_c('div',{staticClass:"text-close-banner"},[_vm._v(" "+_vm._s(_vm.$t('bar.yes'))+" ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }