<template>
  <iframe
    id="game"
    :src="gameLink"
    v-if="gameLink">
  </iframe>
</template>

<script>
import Vue from 'vue';
import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      link: '',
      gameLink: '',
    };
  },
  created() {
    this.link = this.getParameterByName('link');
    const keyStr = 'ypl1of49xv68028roi55rtfkg901ngvh';
    const bytes = CryptoJS.AES.decrypt(this.link, keyStr);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    this.gameLink = originalText;
  },
  methods: {
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line no-useless-escape
      const replace = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${replace}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
  },
};
</script>
