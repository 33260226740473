
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';

@Component
export default class Game extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  @Prop() private gameLink!: string;

  @Prop() readonly backLink!: string;

  @Prop() readonly onLeaveGame!: Function;

  @Prop() readonly provider!: string;

  @Prop() readonly token!: string;

  @Prop() readonly lobbyVersion!: string;

  @Prop() readonly linktoLobby!: string;

  @Prop() isChangeGame: boolean;

  change = false;

  openTime = 0;

  isFirstTime = true;

  load() {
    if (this.isFirstTime) {
      this.openTime = new Date().getTime();
      this.isFirstTime = false;
    }
    if (
      !this.isFirstTime && ((new Date().getTime() - this.openTime) > 3000)
      && !this.GeneralControlModule.changeGameState
    ) {
      if (
        !['SPADEGAMING', 'SPINIX', 'MICROGAMING', 'PRAGMATICPLAY', 'ARCADIA'].includes(this.provider)
        && this.backLink
      ) {
        window.location.href = this.backLink;
      } else {
        this.handleSeamless();
      }
      this.GeneralControlModule.updateIsHideBar(true);
      this.onLeaveGame();
      this.change = true;
    } else {
      this.GeneralControlModule.updateChangeGameState(false);
    }
  }

  handleSeamless() {
    if (!this.linktoLobby) {
      window.location.href = `${this.lobbyVersion}?t=${this.token}`;
    } else {
      window.location.href = this.linktoLobby;
    }
  }
}
