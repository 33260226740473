import { render, staticRenderFns } from "./Lotto.vue?vue&type=template&id=62301b92&scoped=true"
import script from "./Lotto.vue?vue&type=script&lang=ts"
export * from "./Lotto.vue?vue&type=script&lang=ts"
import style0 from "./Lotto.vue?vue&type=style&index=0&id=62301b92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62301b92",
  null
  
)

export default component.exports