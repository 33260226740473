
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import HistoryInstance from '@/store/modules/History';

@Component
export default class WinLose extends Vue {
  @Prop() readonly openLog!: Function;

  HistoryModule = getModule(HistoryInstance, this.$store);

  handleLog(item: object) {
    this.openLog(item);
  }

  color(val: number) {
    let color = '';
    if (val > 0) {
      color = 'greentext';
    }
    if (val < 0) {
      color = 'redtext';
    }
    return color;
  }

  colorBalance(val: number) {
    let color = '';
    if (val !== 0) {
      color = 'yellowtext';
    }
    return color;
  }
}
