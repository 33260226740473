
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RankingInstance from '@/store/modules/Ranking';
import GeneralControl from '@/store/modules/GeneralControl';

@Component
export default class RankInGame extends Vue {
  @Prop() readonly token: string;

  @Prop() readonly type: string;

  RankingModule = getModule(RankingInstance, this.$store);

  GeneralControlModule = getModule(GeneralControl, this.$store);

  lists: any = [];

  count = 0;

  @Watch('type', { immediate: true, deep: true })
  handleType() {
    if (this.count) this.fetchRank(this.type);
    this.count += 1;
  }

  @Watch('GeneralControlModule.rankingModalState', { immediate: true, deep: true })
  handleRankingModal(isRankingModalOpen: boolean) {
    if (isRankingModalOpen) this.fetchRank(this.type);
  }

  async fetchRank(type: string) {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    const params = {
      key: type,
    };
    const rankList = await this.RankingModule.fetchRanking(params);
    this.lists = rankList;
  }
}
