
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import ExchangePoint from '@/store/modules/ExchangePoint';
import { IPointItem, IItem } from '@/interface/PointItem';

@Component({
})
export default class StoreExchange extends Vue {
  @Prop() readonly token: string;

  @Prop() readonly lang: string;

  @Prop() readonly modalState: string;

  @Prop() readonly promotionPrice!: number;

  GeneralControlModule = getModule(GeneralControl, this.$store);

  ExchangePointModule = getModule(ExchangePoint, this.$store)

  itemList: IPointItem[] = [];

  userBalance = {
    point: 0,
    credit: 0,
  }

  isExchange = false;

  selectItem: IPointItem = {
    _id: '',
    points: 0,
    money: 0,
    promotionMoney: 0,
  };

  isHover = false;

  hoverItemId = '';

  isClick = false;

  itemData: IItem = {
    itemId: '',
  };

  processData = {
    isProcess: false,
    isSucess: false,
  }

  msgError = '';

  countForFetchStore = 0;

  @Watch('modalState', { immediate: true, deep: true })
  async resetUserProfile(isOpenModal: boolean) {
    if (this.countForFetchStore && isOpenModal) {
      this.getUserDetail();
      this.fetchPoint();
    }
    this.countForFetchStore += 1;
  }

  async fetchPoint() {
    const data = await this.ExchangePointModule.getPoint();
    this.itemList = data.data;
  }

  async getUserDetail() {
    const { data } = await this.ExchangePointModule.getDetail();
    this.userBalance.point = data.wallet.points;
    this.userBalance.credit = data.wallet.balance;
  }

  closeStore() {
    this.GeneralControlModule.updateStoreModalState(false);
    this.initial();
  }

  async exchangePoint() {
    const res = await this.ExchangePointModule.usePoint(this.itemData);
    this.processData.isProcess = true;
    if (res.status.code) {
      this.msgError = res.status.message;
      setTimeout(() => {
        this.getUserDetail();
        this.fetchPoint();
        this.initial();
      }, 2000);
    } else {
      this.processData.isSucess = true;
      setTimeout(() => {
        this.getUserDetail();
        this.fetchPoint();
        this.initial();
      }, 2000);
    }
  }

  handleHover(isHover: boolean, itemId: string) {
    this.hoverItemId = itemId;
    this.isHover = isHover;
  }

  handleOnClick(isClick: boolean, item: IPointItem) {
    this.isClick = isClick;
    this.isExchange = true;
    this.selectItem = item;
    this.itemData.itemId = item._id;
  }

  backToStore() {
    this.initial();
  }

  initial() {
    this.isExchange = false;
    this.hoverItemId = '';
    this.isClick = false;
    this.selectItem = {
      _id: '',
      points: 0,
      money: 0,
      promotionMoney: 0,
    };
    this.itemData = {
      itemId: '',
    };
    this.processData = {
      isProcess: false,
      isSucess: false,
    };
  }
}
