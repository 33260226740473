import {
  VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  BASE_URL,
  M_POST,
  FETCH_AUTO_PROFILE,
  FETCH_AUTO_INFO,
  SET_AUTO_BONUS,
  CHECK_AUTO_FLOAT,
  FETCH_TRUE,
  GET_QR,
  VERIFY_PAYMENT,
  CREATE_TRANSECTION,
  SENT_AUTO_WITHDRAW,
  CHECK_WITHDRAW,
  AUTO_API_URL,
} from '@/utils/api';

export interface IDepositProfile {
  username: string;
  source: string;
}
export interface IProfile {
  ancestor: Array<string>;
  exp: number;
  iat: number;
  isSeamless: string;
  level: number;
  nickname: string;
  opSession: string;
  parent: string;
  role: string;
  source: string;
  username: string;
  _id: string;
}

@Module({ name: 'Deposit' })
export default class Deposit extends VuexModule {
  autoProfile: {} = {};

  autoInfo: {} = {};

  floatInfo: {} = {};

  trueInfo: {} = {};

  qrInfo: {} = {};

  verify: [] = [];

  refillProfile: IDepositProfile = {} as IDepositProfile;

  @Mutation
  mutateAutoProfile(autoProfile: object) {
    this.autoProfile = autoProfile;
  }

  @Action({ commit: 'mutateAutoProfile' })
  async fetchAutoProfile(token: string) {
    return Vue.axios({
      url: AUTO_API_URL + FETCH_AUTO_PROFILE,
      data: token,
      method: M_POST,
    })
      .then(({ data }) => {
        try {
          const code = data.status;
          if (code.code !== 0) {
            throw data.status.code;
          } else {
            const autoProfile = data.data;
            return autoProfile;
          }
        } catch {
          return 1002;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateAutoInfo(autoInfo: object) {
    this.autoInfo = autoInfo;
  }

  @Action({ commit: 'mutateAutoInfo' })
  async fetchAutoInfo() {
    return Vue.axios({
      url: AUTO_API_URL + FETCH_AUTO_INFO,
      method: M_POST,
    })
      .then(({ data }) => {
        try {
          const { code } = data.status;
          if (code !== 0) {
            throw data.status.code;
          } else {
            const autoInfo = data.data;
            return autoInfo;
          }
        } catch {
          return 1002;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  async setAutoBonus(params: object) {
    return Vue.axios({
      url: AUTO_API_URL + SET_AUTO_BONUS,
      method: M_POST,
      data: params,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateCheckAutoFloat(floatInfo: object) {
    this.floatInfo = floatInfo;
  }

  @Action({ commit: 'mutateCheckAutoFloat' })
  async checkAutoFloat(param: object) {
    return Vue.axios({
      url: AUTO_API_URL + CHECK_AUTO_FLOAT,
      method: M_POST,
      data: param,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          const floatInfo = data.data;
          return floatInfo;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateFetchTrue(trueInfo: object) {
    this.trueInfo = trueInfo;
  }

  @Action({ commit: 'mutateFetchTrue' })
  async fetchTrue(param: object) {
    return Vue.axios({
      url: AUTO_API_URL + FETCH_TRUE,
      method: M_POST,
      data: param,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status;
        } else {
          const trueInfo = data.data.result;
          return trueInfo;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateFetchQr(qrInfo: object) {
    this.qrInfo = qrInfo;
  }

  @Action({ commit: 'mutateFetchQr' })
  async fetchQr(param: object) {
    return Vue.axios({
      url: AUTO_API_URL + GET_QR,
      method: M_POST,
      data: param,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status.message;
        } else {
          const Qr = data;
          return Qr;
        }
      })
      .catch((err: string) => err);
  }

  @Mutation
  mutateUserDeposit(data: IDepositProfile) {
    this.refillProfile = {
      username: data.username,
      source: data.source,
    };
  }

  @Action({ commit: 'mutateUserDeposit' })
  getProfile(data: IProfile) {
    const { username, source }: IDepositProfile = data;
    return {
      username, source,
    };
  }

  @Mutation
  mutateVerifyPayment(data: []) {
    this.verify = data;
  }

  @Action({ commit: 'mutateVerifyPayment' })
  verrifyPayment() {
    return Vue.axios({
      url: AUTO_API_URL + VERIFY_PAYMENT,
      method: M_POST,
    })
      .then(({ data }) => {
        try {
          const { code } = data.status;
          if (code !== 0) {
            throw code;
          } else {
            return data.data;
          }
        } catch {
          return 1002;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  createTransection(param: object) {
    return Vue.axios({
      url: AUTO_API_URL + CREATE_TRANSECTION,
      method: M_POST,
      data: param,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code !== 0) {
          throw data.status.message;
        } else {
          return data;
        }
      })
      .catch((err: string) => err);
  }

  @Action
  reqeustWithdraw(params: object) {
    return Vue.axios({
      url: AUTO_API_URL + SENT_AUTO_WITHDRAW,
      method: M_POST,
      data: params,
    })
      .then(({ data }) => data)
      .catch((err: string) => err);
  }

  @Action
  checkWithdraw() {
    return Vue.axios({
      url: AUTO_API_URL + CHECK_WITHDRAW,
      method: M_POST,
    })
      .then(({ data }) => data)
      .catch((err: string) => err);
  }
}
