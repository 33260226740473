
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import HistoryInstance from '@/store/modules/History';
import History from '@/components/History.vue';
import WinLose from '@/components/WinLose.vue';
import WinLoseLog from '@/components/WinLoseLog.vue';
import PointsHistory from '@/components/PointsHistory.vue';

interface IItem {
  date: string;
  type: string;
}

@Component({
  components: {
    History,
    WinLose,
    WinLoseLog,
    PointsHistory,
  },
})
export default class ModalFramePage extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  HistoryModule = getModule(HistoryInstance, this.$store);

  switchMode = false;

  modalType = 'winlose';

  isLog = false;

  today = this.$moment().format('YYYY-MM-DD');

  // eslint-disable-next-line
  token: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lang: any;

  buttonName = {
    history: 'winlose',
    deposit: 'history',
    points: 'points',
  }

  buttonProcess = {
    isClick: true,
    isHover: false,
  }

  selectMenu = 'winlose';

  hoverMenu = '';

  async created() {
    this.token = this.$route.query.t;
    this.lang = this.$route.query.lang;
    this.$i18n.locale = this.lang;
    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    await this.HistoryModule.fetchWinLose();
  }

  handleHover(isHover: boolean, item: string) {
    this.hoverMenu = item;
    this.buttonProcess.isHover = isHover;
  }

  handleOnClick(isClick: boolean, item: string) {
    this.isLog = false;
    this.buttonProcess.isClick = isClick;
    this.selectMenu = item;
    if (item === 'winlose') {
      this.HistoryModule.fetchWinLose();
    } if (item === 'history') {
      const params = {
        time: 'TODAY',
        date: this.today,
      };
      this.HistoryModule.fetchHistory(params);
    } if (item === 'points') {
      const params = {
        type: 'POINTS',
        date: this.today,
      };
      this.HistoryModule.fetchHistory(params);
    }
  }

  openLog(item: IItem) {
    this.isLog = true;
    const params = {
      type: 'BET',
      date: item.date,
    };
    this.HistoryModule.fetchHistory(params);
  }

  changePeriod(item: string) {
    const params = {
      time: item,
      date: this.today,
    };
    this.HistoryModule.fetchHistory(params);
  }
}
