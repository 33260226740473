
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Deposit from '@/store/modules/Deposit';

@Component
export default class Auto extends Vue {
  DepositModule = getModule(Deposit, this.$store);

  @Prop() readonly token!: string;

  isBonus = false;

  amount = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  code: any

  error = '';

  amountList = [
    {
      amount: '100',
      value: 100,
    },
    {
      amount: '300',
      value: 300,
    },
    {
      amount: '500',
      value: 500,
    },
    {
      amount: '700',
      value: 700,
    },
    {
      amount: '900',
      value: 900,
    },
    {
      amount: '1000',
      value: 1000,
    },
  ]

  accName = '';

  accNumber = '';

  amountFloat = '';

  bankName = '';

  transection = false;

  errorMessage = '';

  async created() {
    const autoProfile = await this.DepositModule.fetchAutoProfile(this.token);
    if (autoProfile.bonus === 'BONUS') {
      this.isBonus = true;
    }
  }

  async switchBonus() {
    const { isBonus } = this;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await this.DepositModule.setAutoBonus({
      isBonus,
    });
    this.error = '';
    this.code = res.code;
    if (this.code !== 0) {
      this.isBonus = false;
      this.error = res.message;
    }
  }

  handleAmount(val: string) {
    this.amount = val;
  }

  async refillFloat() {
    if (Number(this.amount) < 1001) {
      const params = {
        username: this.DepositModule.refillProfile.username,
        agentId: this.DepositModule.refillProfile.source,
        value: Number(this.amount),
      };
      const res = await this.DepositModule.createTransection(params);
      if (res.status.code === 0) {
        this.accName = res.data.result.accountName;
        this.accNumber = res.data.result.accountNumber;
        this.amountFloat = res.data.result.amount;
        this.bankName = res.data.result.bankName;
        this.transection = true;
        setTimeout(() => {
          this.accName = '';
          this.accNumber = '';
          this.amountFloat = '';
          this.bankName = '';
          this.transection = false;
        }, 180000);
      } else if (res.status.code === 2) {
        this.errorMessage = res.status.message;
      }
    } else {
      this.amount = '';
      this.errorMessage = 'deposit.errtextfloat';
    }
  }
}
