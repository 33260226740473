
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import HistoryInstance from '@/store/modules/History';
import History from '@/components/History.vue';
import WinLose from '@/components/WinLose.vue';
import WinLoseLog from '@/components/WinLoseLog.vue';
import PointsHistory from '@/components/PointsHistory.vue';

interface IItem {
  date: string;
  type: string;
}

@Component({
  components: {
    History,
    WinLose,
    WinLoseLog,
    PointsHistory,
  },
})
export default class ModalFrame extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  HistoryModule = getModule(HistoryInstance, this.$store);

  typeList = [
    {
      text: 'modalframe.winlose',
      type: 'winlose',
    },
    {
      text: 'modalframe.history',
      type: 'history',
    },
    {
      text: 'modalframe.points',
      type: 'points',
    },
  ];

  modalType = 'winlose';

  isLog = false;

  today = this.$moment().format('YYYY-MM-DD');

  async created() {
    await this.HistoryModule.fetchWinLose();
  }

  initType(item: IItem) {
    this.modalType = item.type;
    this.isLog = false;
    if (this.modalType === 'winlose') {
      this.HistoryModule.fetchWinLose();
    } if (this.modalType === 'history') {
      const params = {
        time: 'TODAY',
        date: this.today,
      };
      this.HistoryModule.fetchHistory(params);
    } if (this.modalType === 'points') {
      const params = {
        type: 'POINTS',
        date: this.today,
      };
      this.HistoryModule.fetchHistory(params);
    }
  }

  closeModal() {
    this.modalType = 'winlose';
    this.GeneralControlModule.updateModalState(false);
    this.isLog = false;
  }

  openLog(item: IItem) {
    this.isLog = true;
    const params = {
      type: 'BET',
      date: item.date,
    };
    this.HistoryModule.fetchHistory(params);
  }

  changePeriod(item: string) {
    const params = {
      time: item,
      date: this.today,
    };
    this.HistoryModule.fetchHistory(params);
  }
}
