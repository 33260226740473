
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component
export default class LevelPopUp extends Vue {
  @Prop() readonly isLevelUp!: boolean;

  @Prop() readonly fontStyle!: Function;

  @Prop() readonly upEvent!: Function;

  @Prop() readonly bannerUp!: string;

  @Prop() readonly rewardlevel!: number;

  @Prop() readonly level!: number;

  @Prop() readonly messagerankLevel!: string;

  @Prop() readonly messagerankGift!: string;
}
