import {
  VuexModule, Module, Action, MutationAction,
} from 'vuex-module-decorators';
import Vue from 'vue';
import { BASE_URL, FETCH_LOTTO_HISTORY, M_POST } from '@/utils/api';

@Module({ name: 'Lotto' })
export default class Lotto extends VuexModule {
  resultNumber = '';

  amountPoints = 0;

  allTicket = 0;

  round = 0;

  pageState = '';

  countdownTime = {
    hours: '00',
    minutes: '00',
    seconds: '00',
  };

  isCountdown = false;

  isPlaying = false;

  progressBar = 100;

  isProgressBar = false;

  isResultProcess= false;

  isGlowingPlay = 700;

  moveGlowing = 'down'

  isGlowing = false;

  progressBarMenu = 100;

  @MutationAction
  async setResultNumber(resultNumber: string) {
    return {
      resultNumber,
    };
  }

  @MutationAction
  async setAmountPoints(amountPoints: number) {
    return {
      amountPoints,
    };
  }

  @MutationAction
  async setAllTicket(allTicket: number) {
    return {
      allTicket,
    };
  }

  @MutationAction
  async setRound(round: number) {
    return {
      round,
    };
  }

  @MutationAction
  async setState(pageState: string) {
    return {
      pageState,
    };
  }

  @MutationAction
  async setCountdown(data: any) {
    const hours = data.hours.toString().padStart(2, '0');
    const minutes = data.minutes.toString().padStart(2, '0');
    const seconds = data.seconds.toString().padStart(2, '0');
    const countdownTime = {
      hours,
      minutes,
      seconds,
    };
    return {
      countdownTime,
    };
  }

  @MutationAction
  async setIsCountdown(isCountdown: boolean) {
    return {
      isCountdown,
    };
  }

  @MutationAction
  async setIsPlaying(isPlaying: boolean) {
    return {
      isPlaying,
    };
  }

  @MutationAction
  async setProgressBar(progressBar: number) {
    return {
      progressBar,
    };
  }

  @MutationAction
  async setProgressBarMenu(progressBarMenu: number) {
    return {
      progressBarMenu,
    };
  }

  @MutationAction
  async setIsProgressBar(isProgressBar: boolean) {
    return {
      isProgressBar,
    };
  }

  @MutationAction
  async setIsResultProcess(isResultProcess: boolean) {
    return {
      isResultProcess,
    };
  }

  @MutationAction
  async setMoveingpic(moveGlowing: string) {
    return {
      moveGlowing,
    };
  }

  @MutationAction
  async setIsGlowing(isGlowing: boolean) {
    return {
      isGlowing,
    };
  }

  @Action
  fecthLottoHistory() {
    return Vue.axios({
      url: BASE_URL + FETCH_LOTTO_HISTORY,
      method: M_POST,
    })
      .then(({ data }) => data)
      .catch((err: string) => err);
  }
}
