
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RankingInstance from '@/store/modules/Ranking';

@Component
export default class WinLose extends Vue {
  RankingModule = getModule(RankingInstance, this.$store);

  async created() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.$route.query.t}`;
    const params = {
      key: this.$route.query.type,
    };
    await this.RankingModule.fetchRanking(params);
  }
}
