
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

@Component
export default class DropPopUp extends Vue {
  @Prop() readonly dropType!: string;

  @Prop() readonly isDropActive!: boolean;

  @Prop() readonly fontStyle!: Function;

  @Prop() readonly upEvent!: Function;

  @Prop() readonly dropBorderImg!: string;

  @Prop() readonly giftUrl!: string;

  @Prop() readonly dropAmount!: number;

  @Prop() readonly dropMsg!: string;

  @Prop() readonly dropMsggift!: string;

  @Prop() readonly lang!: string;

  @Prop() readonly dropPoint!: number;

  @Prop() readonly popupText!: object;

  @Prop() readonly file!: string;
}
