
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import ItemControl from '@/store/modules/Item';

@Component({
})
export default class Inventory extends Vue {
  @Prop() readonly token: string;

  @Prop() readonly lang: string;

  @Prop() readonly handleDot: Function;

  @Prop() readonly isOpenBar!: boolean;

  @Prop() readonly handleIsOpenBar: Function;

  GeneralControlModule = getModule(GeneralControl, this.$store);

  ItemControlModule = getModule(ItemControl, this.$store);

  activeList = false;

  textRecive = '';

  reciveItem = false;

  selectList = 'inventory.dropdown.all';

  itemList = [];

  allItem = [];

  emptyItem = false;

  errorItem = false;

  errorText = '';

  baseState = true;

  showItem = {
    id: '',
    description: [],
    image: '',
    expDate: {
      HH: 0,
      day: 0,
      mm: 0,
    },
  };

  list = [
    { menu: 'inventory.dropdown.all', mode: 'all' },
    { menu: 'inventory.dropdown.money', mode: 'money' },
    { menu: 'inventory.dropdown.spacial', mode: 'spacial' },
    { menu: 'inventory.dropdown.event', mode: 'event' },
  ];

  handleList(list: string, mode: string) {
    this.selectList = list;
    if (mode !== 'all') {
      const filterMode = this.allItem.filter((item: any) => item.gift.type === mode);
      this.itemList = filterMode;
    } else {
      this.itemList = this.allItem;
    }
  }

  @Watch('isOpenBar', { immediate: true, deep: true })
  handleIsBar() {
    this.getItem();
  }

  async getItem() {
    this.baseState = !this.baseState;

    if (this.baseState) {
      await this.fetchItem();
    }
  }

  handleItem(item: any) {
    this.showItem = {
      // eslint-disable-next-line
      id: item._id,
      description: item.gift.message,
      image: item.image.normal,
      expDate: {
        HH: item.exp.HH,
        day: item.exp.day,
        mm: item.exp.mm,
      },
    };
  }

  async fetchItem() {
    const item = await this.ItemControlModule.fetchItem(this.token);
    this.allItem = item.data;
    this.itemList = item.data;
    const show = item.data[0];
    if (!show) this.emptyItem = true;
    this.showItem = {
      // eslint-disable-next-line
      id: show._id,
      description: show.gift.message,
      image: show.image.normal,
      expDate: {
        HH: show.exp.HH,
        day: show.exp.day,
        mm: show.exp.mm,
      },
    };
    this.handleDot(this.allItem.length);
  }

  async activeItem(id: any) {
    const param: any = {
      questId: [id],
    };
    if (id) {
      const textItem = await this.ItemControlModule.activeItem(param);
      if (textItem.code === 100) {
        this.errorItem = true;
        this.errorText = textItem.message;
      } else if (textItem.status.code === 0) {
        this.textRecive = textItem.data.reward.message;
        this.reciveItem = true;
        setTimeout(() => {
          this.reciveItem = false;
        }, 2000);
        this.fetchItem();
      }
    } else {
      const { data } = await this.ItemControlModule.getLinkMarketing();
      const Features = 'top=0,left=0,height=768,width=1366,resizable=yes';
      window.open(data, Features, 'popup');
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    }
  }

  async openGameMarket() {
    const { data } = await this.ItemControlModule.getLinkMarketing();
    const Features = 'top=0,left=0,height=768,width=1366,resizable=yes';
    window.open(data, Features, 'popup');
    setTimeout(() => {
      this.$router.go(0);
    }, 1000);
  }

  handleExpItem() {
    this.errorItem = false;
    this.fetchItem();
  }

  closeInventory() {
    this.GeneralControlModule.updateInventoryModalState(false);
    this.baseState = !this.baseState;
    this.handleDot(0);
  }
}
