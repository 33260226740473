/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import axios from 'axios';

export default function setup() {
  axios.interceptors.request.use(async (config) => {
    config.headers['x-authorize'] = localStorage.getItem('x-authorize');
    return config;
  }, (err) => Promise.reject(err));
}
