var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isRankUp)?_c('div',{staticClass:"gift-wrapper upper-wrapper-rank",on:{"click":function($event){_vm.isRankUp = false},"keypress":function($event){return _vm.upEvent('rank')}}},[_c('div',{staticClass:"wrapper-level-banner scale-wrapper"},[_c('div',{staticClass:"level-up-text",class:_vm.fontStyle()},[_vm._v(" "+_vm._s(_vm.$t('bar.rank_up'))+" ")]),_c('div',{staticClass:"isLevel-up eng-font"},[_vm._v(" "+_vm._s(this.level)+" ")]),_c('div',{staticClass:"border-rank"},[_c('img',{staticClass:"rankup",attrs:{"src":require(
          `@/assets/images/evo/${_vm.rankUpDetail.name}-${_vm.rankUpDetail.rankCount}.gif`
        )}})]),_c('div',{staticClass:"bg-ground-rank"},[_c('v-lazy-image',{staticClass:"black-bg",attrs:{"src":require(
          `@/assets/images/levelup/bg.png`
        )}})],1),_c('div',{staticClass:"bg-level"},[_c('v-lazy-image',{staticClass:"asset-bg-lv",attrs:{"src":require(
          `@/assets/images/levelup/r_2.gif`
        )}})],1),_c('div',{staticClass:"banner-level"},[_c('v-lazy-image',{staticClass:"asset-banner-lv",attrs:{"src":_vm.bannerUp}})],1),_c('div',{staticClass:"wrapper-amount-drop-level"},[_c('div',[_c('span',{staticClass:"coin-drop",class:_vm.fontStyle()},[_vm._v(" + "+_vm._s(_vm._f("currency")(_vm.rewardrank))+" "),_c('v-lazy-image',{staticClass:"coin-size",attrs:{"src":require(
            `@/assets/images/PGSlot_Lobby_Icon_Coin.png`
          )}})],1)]),_c('div',[_c('span',{staticClass:"coin-drop",class:_vm.fontStyle()},[_vm._v(" + "+_vm._s(_vm._f("currency")(_vm.rewardlevel))+" "),_c('v-lazy-image',{staticClass:"coin-size",attrs:{"src":require(
            `@/assets/images/Store/icon_point.png`
          )}})],1)])]),_c('div',{staticClass:"wrapper-detail-level"},[_c('div',{staticClass:"message-level-up",class:_vm.fontStyle()},[_vm._v(_vm._s(_vm.messagerankLevel))]),_c('div',{staticClass:"message-gift-level",class:_vm.fontStyle(),domProps:{"innerHTML":_vm._s(_vm.messagerankGift)}})]),_c('div',{staticClass:"btn-close-banner",on:{"click":function($event){return _vm.upEvent('rank')}}},[_c('div',{staticClass:"border-close-banner"},[_c('v-lazy-image',{staticClass:"btn-banner",attrs:{"src":require(
          `@/assets/images/button_normal.png`
        )}}),_c('div',{staticClass:"text-close-banner"},[_vm._v(" "+_vm._s(_vm.$t('bar.yes'))+" ")])],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }